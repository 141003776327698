<template>
  <div class="page_container">
    <!-- 静态图片 -->
    <div class="about_banner">
      <img src="../../assets/image/about/aboutus.jpg" alt />

    </div>
    <!-- 导航条 -->
    <div class="about_nav">
      <div class="about_nav_box">
        <ul class="about_nav_detail">
          <li>
            <router-link to="/about">公司简介</router-link>
          </li>
          <li>
            <router-link to="/prize">荣誉资质</router-link>
          </li>
          <li>
            <router-link to="/join">加入我们</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="about_content">
      <div class="about_content_intro clearfix">
        <div class="about_content_picture">
          <img src="../../assets/image/about/architecture.jpg" hspace="18" alt />
        </div>
        <p
          class="about_content_text"
        >上海秉匠信息科技有限公司专注于三维图形引擎和数字化智慧管理系统研发，致力于为工程、智慧城市等领域提供可视化管理综合解决方案。</p>
        <p
          class="about_content_text"
        >秉匠科技联合长安大学多年磨一剑，自主研发的“黑洞”图形引擎性能卓越，可流畅加载多源异构数据、轻松应对超大场景海量数据的全景展现及局部细节的精细化渲染表现。</p>
        <p
          class="about_content_text"
        >企业基于自有“黑洞”引擎，集成分布式计算、数据挖掘、云计算等信息化技术，成功打造了工程建设管理平台和运维管理平台等系列产品，深受客户好评。</p>
        <p
          class="about_content_text"
        >秉匠科技与长安大学、上海路桥集团、Bentley等行业领跑者建立了战略合作关系，基于产、学、研、用的合作模式打造行业解决方案。研发的相关产品已在大型桥梁、市政道路、轨道交通、水利水电、民用建筑等类型的项目中深入应用，成功助力各项目实现精细化管理。</p>
      </div>
      <div class="about_content_hope">
        <div>
          <!-- <img src="static/image/about/shanghai.jpg" alt /> -->
        </div>
        <div class="about_hope_detail">
          <p>企业愿景——让我们一起"建设"这个愈加美好的“世界”。</p>
          <p>企业宗旨——秉承匠心情怀 潜挖数据价值。</p>
          <p>核心价值观——创新、担当、友善、诚信、共赢。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
